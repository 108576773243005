//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { timerOutlinePurple, cross } from '~/static/icons';

const CDN = process.env.CDN_ENDPOINT + '/testprep/static/images';

export default {
    name: 'QdTestHook',
    props: {
        title: {
            type: String,
            default: 'GRE Diagnostic Test'
        },
        icon: {
            type: String,
            default: timerOutlinePurple
        }
    },
    data() {
        return {
            // variables
            showDropdown: false,
            // icons
            cross,
            // constants
            CDN
        };
    },
    methods: {
        onClickHandler() {
            this.$emit('onClick');
            this.showDropdown = false;
        }
    }
};
